import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import homedata from "../data/Homepage.json";
import { getFBVideos } from "../api/homepageApi";
import { useSelector } from "react-redux";
import { handleLang } from "../utils/Helper";
import styles from "../styles/Facebook.module.css";

const FacebookStream = () => {
  const [facebookPageData, setfacebookPageData] = useState("");
  const [activeVideo, setActiveVideo] = useState();
  const [selectLang, setSelectedLang] = useState("");
  const globalselectedlang = useSelector((state) => state.language.globalLang);
  const { data, isLoading } = useQuery("facebookVideos", getFBVideos, {
    select: (data) => data?.data,
  });

  useEffect(() => {
    if (!isLoading){
      setActiveVideo(data[0])
    }
  }, [data]);

  useEffect(() => {
    const lang = handleLang()
    const language = JSON.parse(lang)
    if (globalselectedlang == "en" || language == "en") {
      setfacebookPageData(homedata.en)
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setfacebookPageData(homedata.hi)
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setfacebookPageData(homedata.ta)
    }
    setSelectedLang(language)
  }, [globalselectedlang])

  return (
    <>
      <div className={styles.facebook}>
        <div className={styles.fbtitle}>
          <h1>
            <img src="/assests/icons/om.png" alt="" />
            { facebookPageData?.FacebookTitle }
            <img src="/assests/icons/om.png" alt="" />{" "}
          </h1>
          <p>{ facebookPageData?.FacebookSubtitle }</p>
        </div>
        <div className={styles.dashboard}>
          <div className={styles.FacebookDashboard}>
              <div className={styles.left}>
                <iframe allowFullScreen={true} className={styles.leftMainVid}
                  style={{ border: "none",overflow: "hidden", aspectRatio: "16/9", borderRadius: "1vw",}}
                  src={activeVideo?.attributes?.Facebook_url}>
                </iframe>
              </div> 
          </div>
        </div>
        <div className={styles.btn}>
          <h2> {facebookPageData?.FacebookUndertitle  } </h2>
          <a target="_blank" href="https://www.facebook.com/shaivamorg">
          <button style={{ cursor: "pointer", fontFamily: "Lora" }} >
              <img src="/assests/icons/fb.png" alt="" /> Follow
          </button></a>
        </div>
        <div className={styles.shivbg}>
          <img src={`${facebookPageData?.imgOne}`} alt="" />
        </div>
      </div>
    </>
  );
};

export default FacebookStream;
