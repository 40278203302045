import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { AiOutlineRight } from "react-icons/ai";
import homedata from "../data/Homepage.json";
import styles from "../styles/About.module.css";
import { getwhatsNews } from "../hooks/homePageHooks";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { handleLang } from "../utils/Helper";

const AboutShivam = () => {
  const [selectedLang, setSelectedLang] = useState("");
  const filter = useSelector((state) => state.language.globalLanguageForApi);
  const router = useRouter();
  const whatsnew = getwhatsNews(filter);
  const [aboutpageData, setaboutpageData] = useState("");
  const globalselectedlang = useSelector((state) => state.language.globalLang);

  const onClickHanlder = (path) => {
    if (!path) return;
    router.push(path);
  };
  

  useEffect(() => {
    const lang = handleLang();
    const language = JSON.parse(lang);
   
    if (globalselectedlang == "en" || language == "en") {
      setaboutpageData(homedata.en);
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setaboutpageData(homedata.hi);
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setaboutpageData(homedata.ta);
    }
    setSelectedLang(language)

  }, [globalselectedlang]);

  return (
    <div className={styles.about}>
      <div className={styles.aboutWrapper}>
        <div className={styles.left}>
          <div className={styles.leftTitle}>
            <div className={styles.leftTitleHead}>
              <img src="/assests/icons/om.png" alt="" />
              <h1
                dangerouslySetInnerHTML={{
                  __html: aboutpageData?.AboutShaivamTitle,
                }}
                className={
                  selectedLang == "hi" || selectedLang == "ta"
                    ? styles.hidhiHeading
                    : styles.aboutHeading
                }
              />
              <img src="/assests/icons/om.png" alt="" />
            </div>

            <span>
              <img
                src="/assests/icons/red-ribbon2x.png"
                alt=""
                className={styles.redRibbon}
              />
            </span>
          </div>

          <p> {aboutpageData?.AboutShaivamPara1} </p>
          <p> {aboutpageData?.AboutShaivamPara2} </p>
        </div>

        <div className={styles.right}>
          <div className={styles.InfocardWrapper}>
            <h1>{aboutpageData?.WhatNewTitle}</h1>
            {whatsnew?.data?.map(({ attributes, id }, i) => (
              <React.Fragment key={id}>
                <div
                  className={styles.announcement}
                  onClick={() => onClickHanlder(attributes?.path)}
                >
                  <span>{attributes?.name}</span>
                  <h2>
                    {attributes?.description}
                    <span>
                      <AiOutlineRight />
                    </span>
                  </h2>
                  {/* Commented to remove showing the Date - Mar2024
                  <p>{moment(attributes?.createdAt).format("MMM DD YYYY")}</p>
                  */}
                </div>
                {i !== 4 ? <hr /> : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.backgroundImg}>
        <img src="/assests/images/quizbg.jpg" alt="" />
      </div>
    </div>
  );
};

export default AboutShivam;
