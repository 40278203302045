import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


import { handleAuth, handleLang } from "../utils/Helper";
import { setUserData } from "../actions/userData";
import homedata from "../data/Homepage.json";
import "semantic-ui-css/semantic.min.css";
import styles from "../styles/Home.module.css";



const Homepage = () => {
  const dispatch = useDispatch();
  const [homepagedata, sethompageData] = useState("");
  const [selectLang, setSelectedLang] = useState("");
  const globalselectedlang = useSelector((state) => state.language.globalLang);
  const [globalSearch, setGlobalSearch] = useState("");
  const [search, setSearch] = useState(false);
  const searchForm = { render: false };

  const songsArr = useSelector((state) => state.audio.songs);

  console.log({ songsArr });

  useEffect(() => {
    dispatch(setUserData(JSON.parse(handleAuth())));
  }, []);

  useEffect(() => {
    const lang = handleLang();
    const language = JSON.parse(lang);
    if (globalselectedlang == "en" || language == "en") {
      sethompageData(homedata.en);
    }
    if (globalselectedlang == "hi" || language == "hi") {
      sethompageData(homedata.hi);
    }
    if (globalselectedlang == "ta" || language == "ta") {
      sethompageData(homedata.ta);
    }
    setSelectedLang(language);
  }, [globalselectedlang]);

  const handleChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  useEffect(() => {
    console.log("headerSlug in HomePage");
    // window.__gcse = {}
    document.getElementById("googlesearch-2").innerHTML = "";
    document.getElementById("googlesearch-1").innerHTML = "";
    document.getElementById("googlesearch-0").innerHTML = "";

    console.log("hi there header");
    (function () {
      const cx = "004723011393733804740:mgei4vtavoa";
      const gcse = document.createElement("script");
      gcse.type = "text/javascript";
      gcse.async = true;
      gcse.src = `https://cse.google.com/cse.js?cx=${cx}`;
      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(gcse, s);
    })();

    var renderSearchForms = function () {
      console.log("called", document.readyState);
      console.log("searchForm", searchForm.render);

      if (document.readyState === "complete" && searchForm.render == false) {
        console.log("line 145", searchForm);

        searchForm.render = true;
        window.google.search.cse.element.render({
          div: "googlesearch-2",
          tag: "search",
          gname: "gsearch",
        });

        window.google.search.cse.element.render({
          div: "googlesearch-0",
          tag: "search",
          gname: "gsearch",
        });

        window.google.search.cse.element.render({
          div: "googlesearch-1",
          tag: "search",
          gname: "gsearch",
        });
      } else if (searchForm.render == false) {
        searchForm.render = true;

        window.google.setOnLoadCallback(function () {
          window.google.search.cse.element.render({
            div: "googlesearch-2",
            tag: "search",
            gname: "gsearch",
            onload: function () {
              document.getElementById(s).firstElementChild.style.display =
                "none";
            },
          });

          window.google.search.cse.element.render({
            div: "googlesearch-0",
            tag: "search",
            gname: "gsearch",
            onload: function () {
              document.getElementById(s).firstElementChild.style.display =
                "none";
            },
          });

          window.google.search.cse.element.render({
            div: "googlesearch-1",
            tag: "search",
            gname: "gsearch",
            onload: function () {
              document.getElementById(s).firstElementChild.style.display =
                "none";
            },
          });
        }, true);
      }
    };
    console.log("serchForms", renderSearchForms);
    window.__gcse = {
      parsetags: "explicit",
      callback: renderSearchForms,
    };
  }, []);

  useEffect(() => {
    console.log("hi there");

    const input = document.getElementById("search1");
    const submit = (e) => {
      console.log("on Submit", e);
      if (e.keyCode === 13) {
        const element = window.google.search.cse.element.getElement("gsearch");
        console.log("element", element);
        element.execute(globalSearch);
      }
    };
    input.addEventListener("keyup", submit);

    return () => input.removeEventListener("keyup", submit);
  }, [globalSearch]);

  const handleClick = () => {
    if (value.lenght > 0) {
      const element = window.google.search.cse.element.getElement("gsearch");

      element.execute(value);
    }
  };

  return (
    <>
      <div className={styles.home}>
        <div className={styles.homeHeader}>{/* <Header /> */}</div>
        <div className={styles.middleContent}>
          <div className={styles.head}>
            <div className={styles.headHeading}>
              <img
                src="/assests/icons/yellow.png"
                alt=""
                className={styles.fristimg}
              />
              <h1
                className={
                  globalselectedlang == "ta" || selectLang == "ta"
                    ? styles.homeHeading
                    : ""
                }
                dangerouslySetInnerHTML={{ __html: homepagedata?.mainTitle }}
              />
              <img src="/assests/icons/trisul.png" alt="" />
            </div>
            <div className={styles.subTitle}>
              <h2>{homepagedata?.mainSubTitle}</h2>
            </div>
            <div className={styles.subTitleMobile}>
              <h2> {homepagedata?.mainSubTitle} </h2>
            </div>
            <div className={styles.subTitleMobileMobile}>
              <h2> {homepagedata?.mainSubTitle} </h2>
            </div>
            <div className={styles.inputbox}>
              <button className={styles.yellowBtn}>
                <img src="/assests/icons/sIcon.png" alt="" />
              </button>

              {/* <input
                type="text"
                className={styles.inputfield}
                placeholder={homepagedata?.searchPlaceHolder}
                onChange={(e) => setdata(e.target.value)}
                // onKeyDown={handleEnter}
              /> */}
                <div className={styles.searchInputWrapperContentHome}>
                <div id="search-container-1">
                  <input
                    name="search1"
                    id="search1"
                    value={globalSearch}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                  <button
                    id="submit"
                    onClick={handleClick}
                    style={{ display: "none" }}
                  >
                    送出
                  </button>
                  <div id="googlesearch-1"></div>
                </div>
                </div>
            </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Homepage;
