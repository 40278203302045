import React from "react";
import styles from "../styles/Gallery.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import homedata from "../data/Homepage.json";
import { useRef } from "react";
import { useState } from "react";
import { getGallaries } from "../hooks/homePageHooks";
import { Config } from "../utils/config";
import { useRouter } from "next/router";
import { handleLang } from "../utils/Helper";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { RxDot, RxDotFilled } from "react-icons/rx";

const Gallery = () => {
  const router = useRouter();
  const [pageParam, setPageParam] = useState(1);
  const { data: imagesTab } = getGallaries(pageParam);
  const [gallaryData, setGallaryData] = useState("");
  const [selectLang, setSelectedLang] = useState("");
  const globalselectedlang = useSelector((state) => state.language.globalLang);
  let settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "330px",
    // centerMargin: "360px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    arrows: true,
    dotsClass: styles.slick__dots,
    className: "centerImg",

    beforeChange: (current, next) => {
      setSlideIndex(next);
    },

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          // centerPadding: "400px",
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerPadding: "40px",
        },
      },
    ],
  };

  const ref = useRef();

  const Next = () => {
    if (pageParam < imagesTab?.meta?.pagination?.total) {
      setPageParam((prev) => prev + 1);
      ref.current.slickNext();
      return;
    }
    ref.current.slickNext();
  };
  const Prev = () => {
    if (pageParam > 1) {
      setPageParam((prev) => prev - 1);
      ref.current.slickPrev();
      return;
    }
    ref.current.slickPrev();
  };

  const [slideIndex, setSlideIndex] = useState(0);

  function handleClick(path) {
    router.push(path);
  }

  useEffect(() => {
    const lang = handleLang();
    const language = JSON.parse(lang);
    if (globalselectedlang == "en" || language == "en") {
      setGallaryData(homedata.en);
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setGallaryData(homedata.hi);
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setGallaryData(homedata.ta);
    }
    setSelectedLang(language);
  }, [globalselectedlang]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h1>
          <img src="/assests/icons/om.png" alt="" />
          {gallaryData?.GalleryTitle}
          <img src="/assests/icons/om.png" alt="" />
        </h1>
        <p>{gallaryData?.GallerySubtitle}</p>
      </div>
      <div className={styles.slid}>
        <Slider
          {...settings}
          className={styles.sliderWrapper}
          ref={ref}
          dots={false}
        >
          {imagesTab?.map(({ attributes, id }, index) => {
            console.log("attributes",attributes?.img?.data?.attributes?.url)
            return (
              <div
                className={index === slideIndex ? styles.active : styles.one}
                key={id}
                onClick={() => handleClick(attributes?.url)}
              >

                <img
                  src={
                    attributes.rawImageUrl
                      ? attributes.rawImageUrl
                      : `${
                          attributes?.img?.data?.attributes?.url
                            
                        }` || ""
                  }
                  alt=""
                />
              </div>
            );
          })}
        </Slider>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {imagesTab?.map((_, index) => (
            <div>
              {index === slideIndex ? (
                <RxDotFilled size={"5vh"} color={"#C45B4A"} />
              ) : (
                <RxDot size={"2.5vh"} color={"#C45B4A"} />
              )}
            </div>
          ))}
        </div>

        <div className={styles.btn}>
          <button onClick={Prev}>
            <img src="/assests/icons/leftgallary.png" alt="" />
          </button>
          <button onClick={Next}>
            {" "}
            {/* <AiOutlineRight />{" "} */}
            <img src="/assests/icons/rightGallary.png" alt="" />
          </button>
        </div>
      </div>
      <div className={styles.backgroundImg}>
        <img src={gallaryData?.imgTwo} alt="" />
      </div>
    </div>
  );
};

export default Gallery;
