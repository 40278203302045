import React, { useEffect, useState } from "react";
import { getFeaturedPrayerOfTheDay, getPrayerOfTheDay } from "../hooks/homePageHooks";
import homedata from "../data/Homepage.json";

import styles from "../styles/PrayerSection.module.css";
import { useSelector } from "react-redux";
import { handleLang } from "../utils/Helper";
import { useRouter } from "next/router";

const PrayerSection = () => {
  const router = useRouter();
  const [pageParam, setPageParam] = useState(1);
  const [prayerpageData, setPrayerPageData] = useState("");
  const [description, setDescription] = useState("");
  const [meaning, setMeaning] = useState("");
  const [hubId, setHubId] = useState("");
  const [batch, setBatch] = useState(false)
  const globalselectedlang = useSelector((state) => state.language.globalLang);
  const dayOfYear = Math.floor((new Date() - new Date(new Date().getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
  
  
  const prayer = getPrayerOfTheDay(pageParam);
  const featurePrayer = getFeaturedPrayerOfTheDay()
  
  let start_index;
  let end_index;
  let start_index_of_meaning;
  let end_index_of_meaning;
  const maxNumberOfPrayer = 1;

  useEffect(() => {
   
    // handle autoChange
      if(!batch){
      if(prayer?.data && dayOfYear+(maxNumberOfPrayer*2)<prayer?.meta?.pagination?.total){
        setPageParam(dayOfYear+maxNumberOfPrayer)
        setBatch(true)
      }
      
    }
    else if(prayer?.data && prayer?.meta?.pagination?.total > maxNumberOfPrayer && dayOfYear+(maxNumberOfPrayer*2) > prayer?.meta?.pagination?.total){
      setPageParam(dayOfYear+maxNumberOfPrayer % prayer?.meta?.pagination?.total)
      setBatch(true)
    }
  }, [prayer?.data]);

  useEffect(() => {
    if (prayer && prayer?.data && prayer?.data[0]?.attributes && featurePrayer?.data?.length == 0) {
      start_index =
        prayer?.data[0]?.attributes?.Description?.indexOf("Prayer of the Day");
      end_index =
        prayer?.data[0]?.attributes?.Description?.indexOf("Song as Romanized text");
      start_index_of_meaning =
        prayer?.data[0]?.attributes?.Description?.indexOf(
          "Meaning of the Prayer Song"
        );
      end_index_of_meaning =
        prayer?.data[0]?.attributes?.Description?.indexOf("Notes");
      setDescription(
        prayer?.data[0]?.attributes?.Description?.slice(
          start_index + 58,
          end_index - 11
        )
      );
      setMeaning(
        prayer?.data[0]?.attributes?.Description?.slice(
          start_index_of_meaning + 33,
          end_index_of_meaning - 11
        )
      );
      setHubId(prayer?.data[0]?.id);
    }
  }, [prayer?.data]);

  useEffect(() => {
    if (featurePrayer?.data?.length > 0) {
      start_index =
        prayer?.data[0]?.attributes?.Description?.indexOf("Prayer of the Day");
      end_index =
        prayer?.data[0]?.attributes?.Description?.indexOf("Song as Romanized text");
      start_index_of_meaning =
        prayer?.data[0]?.attributes?.Description?.indexOf(
          "Meaning of the Prayer Song"
        );
      end_index_of_meaning =
        featurePrayer?.data[0]?.attributes?.Description?.indexOf("Notes");
      setDescription(
        featurePrayer?.data[0]?.attributes?.Description?.slice(
          start_index + 58,
          end_index - 11
        )
      );
      setMeaning(
        featurePrayer?.data[0]?.attributes?.Description?.slice(
          start_index_of_meaning + 33,
          end_index_of_meaning - 11
        )
      );
      setHubId(featurePrayer?.data[0]?.id);
    }
  }, [featurePrayer?.data]);


  

  useEffect(() => {
    const lang = handleLang();
    const language = JSON.parse(lang);
    if (globalselectedlang == "en" || language == "en") {
      setPrayerPageData(homedata.en);
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setPrayerPageData(homedata.hi);
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setPrayerPageData(homedata.ta);
    }
  }, [globalselectedlang]);

  return (
    <div className={styles.PrayerSection}>
      <div className={styles.PrayerWrapper}>
        <div className={styles.prayerHead}>
          <h1>
            <img src="/assests/icons/om.png" alt="" />{" "}
            {prayerpageData?.PrayerTItle}{" "}
            <img src="/assests/icons/om.png" alt="" />
          </h1>
        </div>

        <div className={styles.prayerBody}>
          <div className={styles.prayerBodyLeft}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>

          <div className={styles.prayerBodyMiddle}>
            <span>
              <img src="/assests/icons/left.png" alt="" /> Meaning
              <img src="/assests/icons/right.png" alt="" />
            </span>
          </div>

          <div className={styles.prayerBodyRight}>
            <div dangerouslySetInnerHTML={{ __html: meaning }} />
          </div>
        </div>

        <div className={styles.btn}>
          <button
            style={{ cursor: "pointer" }}
            onClick={() => router.push(`/hindu-prayer-hub/detail/${hubId}`)}
          >
            {prayerpageData?.PrayerReadMoreButton}
            
          </button>
        </div>
      </div>

      <div className={styles.prayerBg}>
        <img src={prayerpageData?.imgThree} alt="" />
      </div>
    </div>
  );
};

export default PrayerSection;
