import React, { useEffect } from 'react';
import Router from 'next/router';

const ScrollToTop = () => {
    useEffect(() => {
        const handleRouteChange = () => {
            window.scrollTo(0, 0);
        };
        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    return null;
};

export default ScrollToTop;
