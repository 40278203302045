import React, { useEffect } from "react";

import Homepage from "./Homepage";
import ShivamQuiz from "../sections/ShivamQuiz";
import AboutShivam from "../sections/AboutShivam";
import AppDownload from "../sections/AppDownload";
import PrayerSection from "../sections/PrayerSection";
import FacebookStream from "../sections/FacebookStream";
import { FeatureContent } from "../sections/FeatureContent";
import Gallery from "../sections/Gallery";
import ScrollToTop from "../components/scrollToTop";
import { useState } from "react";










const Hero = () => {

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);








  return (
    <div>
      <ScrollToTop />
      <Homepage />
      <AboutShivam />
      <FacebookStream />
      <Gallery />
      <ShivamQuiz />
      <PrayerSection />
      <FeatureContent />
      <AppDownload />
    </div>
  );
};

export default Hero;
