import _, { isNil } from "lodash";
import { useRouter } from "next/router";
import { useInfiniteQuery } from "react-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import homedata from "../data/Homepage.json";
import { clearQuiz, quiz, quizResult } from "../actions";
import { getQuiz } from "../api/homepageApi";
import styles from "../styles/Quiz.module.css";
import { handleLang } from "../utils/Helper";

const ShivamQuiz = () => {
  const router = useRouter();
  const [options, setOptions] = useState([]);
  const [pageParam, setPageParam] = useState(1);
  const [quizData, setQuizData] = useState("");
  const [batch, setBatch] = useState(false)
  const [total, setTotal] = useState(1)
  
  const globalselectedlang = useSelector((state) => state.language.globalLang);
  const maxNumberOfQuiz = 5;
  const filter = useSelector((state) => state.language.globalLanguageForApi);
  const answers = useSelector((state) => state.features.quizAnswers);

  const { data, isLoading } = useInfiniteQuery(
    ["getQuiz", { pageParam }, { filter }],
    getQuiz,
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage, _pages) => {
        if (pageParam > lastPage?.meta?.pagination?.total) return undefined;
        else return 1;
      },
      select: (data) => data?.pages[0],
    }
  );

  const incHandler = () => {
    const pageTotal = data?.meta?.pagination?.total;
    if(pageParam < pageTotal) {
      setPageParam(prev => prev + 1)
      setTotal(prev => prev + 1)
    }
  }

  function addOptions() {
    if (data?.data) {
      Object.entries(data?.data[0]?.attributes || [])?.map((option) => {
        if (option[0].includes("option") && options.length !== 4)
          setOptions((prev) => [...prev, { [option[0]]: option[1] }]);
      });
    }
  }
  if (!isLoading && options.length !== 4) {
    addOptions();
  }

  /* Code changes for Quiz Ordering Fixes - Oct2024 - Start */
  // Function to calculate cumulative day of the year across years
  function getCumulativeDayOfYear() {
    //the baseYear in which this quiz-ordering fixes rolled out to production
    const baseYear = 2024;
    const date = new Date();
    const currentYear = date.getFullYear();
    const dayOfYear = Math.floor((date - new Date(currentYear, 0, 0)) / 1000 / 60 / 60 / 24);
    let cumulativeDay = 0;
    
    for (let year=baseYear; year<currentYear; year++) {
      // Add 365 or 366 days depending on whether it's a leap year
      cumulativeDay += (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) ? 366 : 365;
    }
    console.log(`PaginationTotal-${data?.meta?.pagination?.total},PageParam-${pageParam},DayOfYear- ${dayOfYear}`);
    cumulativeDay += dayOfYear;
    return cumulativeDay;
  }
  // Function to get the quiz index for the given date
  function getQuizIndexForDate(totalQuizzes) {
    const cumulativeDay = getCumulativeDayOfYear(); // Calculate cumulative days since base year
    return (cumulativeDay - 1) % totalQuizzes + 1; // Use cumulative day to calculate the quiz index
  }

  useEffect(() => {
    setOptions([]);
    const pageTotal = data?.meta?.pagination?.total;
    // handle autoChange
    if(!batch && data) {
      let pageNo = getQuizIndexForDate(pageTotal)
      setPageParam(pageNo)
      setBatch(true)
      setTotal(1); //reset quiz question to 1 
    }
  }, [data,batch]);
  /* Code changes for Quiz Ordering Fixes - Oct2024 - End */

  const dispatch = useDispatch();

  const handleAddAnswer = (ans, { id, correct }) => {
    dispatch(quiz(ans));
    let temp = { [id]: correct === ans[id] };
    dispatch(quizResult(temp));
  };

  useEffect(() => {
    const lang = handleLang();
    const language = JSON.parse(lang);
    if (globalselectedlang == "en" || language == "en") {
      setQuizData(homedata.en);
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setQuizData(homedata.hi);
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setQuizData(homedata.ta);
    }
    setBatch(false)
  }, [globalselectedlang]);

  const [selected, setSelected] = useState(false);

  console.log({ selected });

  return (
    <div className={styles.quizWrapper}>
      <div className={styles.quiz}>
        <div className={styles.quizTitle}>
          <div className={styles.quiztitleHeading}>
            <img src="/assests/icons/om.png" alt="" />
            <h1
              dangerouslySetInnerHTML={{ __html: quizData?.ShaivamQuizTitle }}
            />
            <img src="/assests/icons/om.png" alt="" />
          </div>
          <p> {quizData?.ShaivamquizeSubtitle} </p>
        </div>

        <p>
			{quizData?.QuizQuestionLabel + total } / {maxNumberOfQuiz}
        </p>

        <div className={styles.quizScroll}>
          <h2>{data?.data?.[0]?.attributes?.questions}</h2>

          <div className={styles.options}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {options?.map((option, i) =>
                option[`option${i + 1}`] === "" ? (
                  <label></label>
                ) : (
                  <label
                    key={i}
                    htmlFor="first"
                    style={{ textAlign: "center", position: "relative" }}
                    className={`${
                      answers[data?.data[0]?.id]
                        ? data?.data[0]?.attributes?.answers ===
                          option[`option${i + 1}`]
                          ? styles.correct_answer
                          : answers[data?.data[0]?.id] ===
                            option[`option${i + 1}`]
                          ? data?.data[0]?.attributes?.answers ===
                            option[`option${i + 1}`]
                            ? styles.correct_answer
                            : styles.wrong_answer
                          : styles.unselected_answer
                        : styles.unselected_answer
                    } 
                    `}
                    onClick={() =>
                      handleAddAnswer(
                        {
                          [data?.data?.[0]?.id]: option[`option${i + 1}`],
                        },
                        {
                          id: [data?.data[0]?.id],
                          correct: data?.data[0]?.attributes?.answers,
                        }
                      )
                    }
                  >
                    {option[`option${i + 1}`]}
                    {answers[data?.data[0]?.id] === option[`option${i + 1}`] ? (
                      data?.data[0]?.attributes?.answers ===
                      option[`option${i + 1}`] ? (
                        <img
                          style={{
                            position: "absolute",
                            right: "-10%",
                            bottom: "25%",
                          }}
                          alt=""
                          src="/assests/icons/correct.svg"
                        />
                      ) : (
                        <img
                          style={{
                            position: "absolute",
                            right: "-10%",
                            bottom: "25%",
                          }}
                          alt=""
                          src="/assests/icons/closequiz.svg"
                        />
                      )
                    ) : (
                      ""
                    )}

                    {answers[data?.data[0]?.id]
                      ? data?.data[0]?.attributes?.answers ===
                          option[`option${i + 1}`] && (
                          <img
                            style={{
                              position: "absolute",
                              right: "-10%",
                              bottom: "25%",
                            }}
                            alt=""
                            src="/assests/icons/correct.svg"
                          />
                        )
                      : ""}
                  </label>
                )
              )}
            </div>

            <div className={styles.btn}>
              {total < maxNumberOfQuiz ? (
                <button
                  disabled={isNil(answers[data?.data?.[0]?.id])}
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  onClick={() => {
                    total < maxNumberOfQuiz
                      ? incHandler()
                      : {};
                  }}
                >
                  {quizData?.QuizSubmitButton}
                  <img src="/assests/icons/submit.png" alt="" />{" "}
                </button>
              ) : (
                <button
                  disabled={isNil(answers[data?.data?.[0]?.id])}
                  style={{ cursor: "pointer" }}
                  onClick={() => router.push("/quiz-result")}
                >
                  {quizData?.QuizSubmitButton}{" "}
                  <img src="/assests/icons/submit.png" alt="" />{" "}
                </button>
              )}

              <span
                className={styles.anchor}
                onClick={() => {
                  dispatch(clearQuiz());
                  router.push("/quiz");
                }}
              >
                {quizData.QuizAnswerbutton}
                <img src="/assests/icons/right.png" alt="" />
              </span>
            </div>
            {/* <div className={styles.anchor}></div> */}
          </div>
        </div>
      </div>

      {/* <div className={styles.quizbg} >
        <img src="/assests/images/quizbg.jpg" alt="" />
      </div> */}
    </div>
  );
};

export default ShivamQuiz;
