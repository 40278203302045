import { Router, useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import homedata from "../data/Homepage.json";

import styles from "../styles/AppDownload.module.css";
import { handleLang } from "../utils/Helper";

const AppDownload = () => {

const router  = useRouter();


  const [appDownloadData, setAppDownloadData] = useState("");
  const [selectLang, setSelectedLang] = useState("");
  const globalselectedlang = useSelector((state) => state.language.globalLang);


  useEffect(() => {
    const lang = handleLang()
    const language = JSON.parse(lang)
    if (globalselectedlang == "en" || language == "en") {
      setAppDownloadData(homedata.en)
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setAppDownloadData(homedata.hi)
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setAppDownloadData(homedata.ta)
    }
    setSelectedLang(language)
  }, [globalselectedlang])















  return (
    <div className={styles.download}>
      <div className={styles.downloadWrapper}>
        <div className={styles.left}>
          <img src="/assests/icons/phonemd.png" alt="" />
          
        </div>

        <div className={styles.right}>
          {/* <h1>
            
            <img src="/assests/icons/om.png" alt="" /> 
            {appDownloadData.DownloadAppTitle   }
             <img src="/assests/icons/om.png" alt="" />
          </h1> */}

          <div className={styles.AppdownLoadTitle}  >
            <img src="/assests/icons/om.png" alt="" />
            <h1 dangerouslySetInnerHTML={{ __html: appDownloadData?.DownloadAppTitle }}  
              className={globalselectedlang == "ta" || selectLang == "ta" ? styles.AppHeading : ""}
                />
            <img src="/assests/icons/om.png" alt="" />

          </div>

          {
            appDownloadData?.DownloadAppPointer?.data?.map((item, index) => {
              return <h2 key={index} >

                <img src="/assests/icons/shiva-eye.png" alt="" />
                {item.title}

              </h2>
            })
          }

          <div className={styles.social}>
            <img src="/assests/icons/appstore.png" alt="" style={{ cursor:"pointer"  }}
            onClick={() => router.push("https://apps.apple.com/in/app/shaivam-org-mobile-app/id1575138510")} />
            <img src="/assests/icons/googleplay.png" alt="" style={{ cursor: "pointer" }}
              onClick={() => router.push("https://play.google.com/store/apps/details?id=org.shaivam")}
             />
          </div>
        </div>
      </div>
      <div className={styles.downloadWrappeMobilew}>
        <div className={styles.mobileTitle}>
          <img src="/assests/icons/om.png" alt="" style={{ height: "20px", marginRight: "10px" ,marginTop:"10px" }} />

          <h1 dangerouslySetInnerHTML={{ __html: appDownloadData?.DownloadAppTitle }}
            className={globalselectedlang == "ta" || selectLang == "ta" ? styles.AppHeading : ""}

          />
          <img src="/assests/icons/om.png" alt="" style={{ height: "20px", marginLeft: "10px", marginTop: "10px" }}  />

        </div>
        <div className={styles.left}>
          <img src="/assests/icons/phonemd.png" alt="" />
          <div className={styles.social}>
            <img src="/assests/icons/appstore.png" alt="" 
              onClick={() => router.push("https://apps.apple.com/in/app/shaivam-org-mobile-app/id1575138510")} 
            style={{ cursor: "pointer" }}
             />
            <img src="/assests/icons/googleplay.png" alt=""
              style={{ cursor: "pointer" }}
              onClick={() => router.push("https://play.google.com/store/apps/details?id=org.shaivam")}
             />
          </div>
        </div>

        <div className={styles.right}>
        
{
            appDownloadData?.DownloadAppPointer?.data?.map((item, index) =>{
          return  <h2 key={index} >
            
            <img src="/assests/icons/shiva-eye.png" alt="" />
            {item.title}
           
          </h2>
            })
}












        
        </div>
      </div>

      <div className={styles.lowerbg}></div>
    </div>
  );
};

export default AppDownload;
