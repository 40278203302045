import React, { useEffect, useState } from "react";
import Link from "next/link";
import homedata from "../data/Homepage.json";

import {
  getFeaturedContents,
  getUpcomingEvents,
  get_special_featured_Contents,
} from "../hooks/homePageHooks";

import styles from "../styles/FeatureContent.module.css";
import { handleLang } from "../utils/Helper";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import moment from "moment";

export const FeatureContent = () => {
  const filter = useSelector((state) => state.language.globalLanguageForApi);
  const events = getUpcomingEvents();
  const featuredContents = getFeaturedContents();
  const specialEvents = get_special_featured_Contents(filter);
  const [FeatureContentData, setFeatureContentData] = useState("");
  const globalselectedlang = useSelector((state) => state.language.globalLang);
  const router = useRouter();

  const onClickHanlder = (path) => {
    if (!path) return;
    router.push(path);
  };

  useEffect(() => {
    const lang = handleLang();
    const language = JSON.parse(lang);

    if (globalselectedlang == "en" || language == "en") {
      setFeatureContentData(homedata.en);
    }

    if (globalselectedlang == "hi" || language == "hi") {
      setFeatureContentData(homedata.hi);
    }

    if (globalselectedlang == "ta" || language == "ta") {
      setFeatureContentData(homedata.ta);
    }
  }, [globalselectedlang]);

  return (
    <div className={styles.FeatureContentWrapper}>
      <div className={styles.FeatureContent}>
        <div className={styles.upper}>
          <h1 style={{ fontFamily: "Lora" }}>
            {FeatureContentData?.UpcomingeventsTitle}
          </h1>

          <Link href="">
            <a
              onClick={() =>
                router.push("/calendar-important-events-of-this-year")
              }
            >
              {FeatureContentData?.UpcomingeventsViewButton}{" "}
              <img src="/assests/icons/right.png" alt="" />{" "}
            </a>
          </Link>
        </div>

        <div className={styles.lower}>
          {events?.data?.map(({ attributes, id }, _i) => (
            <div className={styles.events} key={id} onClick={() => router.push(attributes.Calendar_url.replace("https://shaivam.org",""))} style={{cursor:"pointer"}}>
              <div className={styles.right}>
                <h2>{attributes?.Calendar_title}</h2>
                <h2 style={{ marginTop: "1rem",textAlign:"left",width:"100%" }}>
                {moment(attributes?.calendar_from_date).format(
                    "DD-MM-YYYY"
                  )}&nbsp;
                  ({moment(attributes?.calendar_from_date).format(
                    "dddd"
                  ).substring(0,3)})
                  
                </h2>
              </div>
              <div className={styles.left}>
                <h3>{attributes?.Calendar_description}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.FeatureContentBody}>
        <div className={styles.FeatureHead}>
          <h1>
            <img src="/assests/icons/om.png" alt="" />{" "}
            {FeatureContentData?.FeaturedContentTitle}
            <img src="/assests/icons/om.png" alt="" />
          </h1>
        </div>

        <div className={styles.contentBody}>
          {featuredContents?.data?.map(({ attributes }, i) => (
            <div
              onClick={() => onClickHanlder(attributes?.uri)}
              className={styles.content}
              key={i}
              style={{ cursor: "pointer" }}
            >
              <p>{attributes?.Feature_Title}</p>
              <h4 >{attributes?.Feature_Description}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.SepcialContentBody}>
        <div className={styles.FeatureHead}>
          <h1>
            <img src="/assests/icons/om.png" alt="" />{" "}
            {FeatureContentData?.specialcontentTitle}
            <img src="/assests/icons/om.png" alt="" />
          </h1>
        </div>

        <div className={styles.contentBody}>
          {specialEvents?.data?.map(({ attributes }, i) => (
            <div
              onClick={() => onClickHanlder(attributes?.uri)}
              className={styles.content}
              key={i}
              style={{ cursor: "pointer" }}
            >
              <p>{attributes?.Title}</p>
              <h4>{attributes?.description}</h4>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.featureBg}>
      <img src={FeatureContentData?.imgFour} alt="" />
      </div>
    </div>
  );
};
